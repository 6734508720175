.contact {
    /* margin: 80px auto; */
    /* max-width: 90%; */
    display: flex;
    align-content: center;
    justify-content: space-between;
    background-color: #aa1920;
    /* background-color: #236c7e; */
    /* display:flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;  
    /* margin:10px 0px; */
    /* margin-top: 80px; */
    /* padding: 30px 0px; */
}

.contact-col {
    flex-basis: 50%;
    color: white;
    float: right;
    /* padding: 30px 0px; */
    margin: 10px 30px;
text-align: center;  

}

.contact-col h3 {
    color: #EBECFE;

    /* color: #000F38; */
    font-weight: 500;
    font-size: 25px;
    display: flex;
    align-items: center;
    margin-bottom: 20px; 
 
}

.contact-col p {    text-align: right;

    color: #EBECFE;
    max-width: 450px;
    list-style: 0.3;
}

.contact-col ul li {
    color: #EBECFE;

    display: flex;
    align-items: center;
    margin: 20px 0;
}

.icon {
    font-size: 25px;
    margin-right: 10px;
}

.form-contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}



.contact form input,
.contact form textarea {



    display: block;
    width: 100%;
    background: #EBECFE;
    padding: 15px;
    border: 0;
    outline: 0;
    margin-bottom: 15px;
    margin-top: 5px;
    resize: none;

}

.contact-col span {
    display: block;
    margin: 20px 0;
}

.social ul li {
    display: inline-block;
    margin: 10px;

}

.icon-social {
    width: 40px;
    height: 35px;
    color: #FFF;
}

.btn {
    background: #FFF;
    color: #212121;
    padding: 14px 25px;
    font-size: 16px;
    border-radius: 30px;
    cursor: pointer;
    border: 0;
    outline: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* media qury */

@media (max-width:700px) {

    .contact {

        display: block;
        text-align: right;
    }

    .contact-col {
        padding: 20px;
        width: 100%;

    }

}